.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
/* csss */
main {
  margin: auto;
  text-align: center;
}
